import Api from "./api";

const RESOURCE_NAME = "ReturnTrucks/";

const getReturnTrucks = (auth) => {
    return Api().get(
        RESOURCE_NAME,
        {
            headers: {
                Authorization: returnAccessToken(auth),
            }
        });
}

const getReturnTruckById = (auth, id) => {
    return Api().get(
        RESOURCE_NAME + id + `?access_token=${returnAccessToken(auth)}`
    );
};
const addReturnTruck = (auth, payload) => {
    return Api().post(RESOURCE_NAME, payload, {
        headers: {
            Authorization: returnAccessToken(auth)
        }
    });
};

const patchReturnTruckById = (auth, id, payload) => {
    payload.updatedAt = new Date(Date.now());
    return Api().patch(
        RESOURCE_NAME + id + `?access_token=${returnAccessToken(auth)}`,
        payload,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
};

const deleteReturnTruckById = (auth, id) => {
    return Api().delete(
        RESOURCE_NAME + id + `?access_token=${returnAccessToken(auth)}`,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
};

const uploadConfigurations = (auth, returnTruckId, payload) => {
    return Api().post(
        RESOURCE_NAME  + returnTruckId + "/configuration/upload",
        payload,
        {
            headers: {
                Authorization: returnAccessToken(),
                "Content-Type": "multipart/form-data"
            }
        }
    );
}

function returnAccessToken(auth) {
    return auth ? auth.id : null;
}

export default {
    getReturnTrucks,
    getReturnTruckById,
    addReturnTruck,
    patchReturnTruckById,
    deleteReturnTruckById,
    uploadConfigurations,
};
